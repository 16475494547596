@font-face {
  font-family: "Gothan";
  src: local("Gothan"),
  url("../assets/fonts/Gotham Pro/Gotham-MediumPro.otf") format("truetype");
  font-weight: normal;
}
@font-face {
  font-family: "Gotham Light";
  src: local("Gothan"),
  url("../assets/fonts/Gotham/Gotham-Light.otf") format("truetype");
  font-weight: normal;
}
@font-face {
  font-family: "Gotham BookPro";
  src: local("Gotham BookPro"),
  url("../assets/fonts/Gotham Pro/Gotham-BookPro.otf") format("truetype");
  font-weight: normal;
}
@font-face {
  font-family: "Gotham-MediumPro";
  src: local("Gotham-MediumPro"),
  url("../assets/fonts/Gotham Pro/Gotham-MediumPro.otf") format("truetype");
  font-weight: normal;
}
@font-face {
  font-family: "Gotham-BoldPro";
  src: local("Gotham-BoldPro"),
  url("../assets/fonts/Gotham Pro/Gotham-BoldPro.otf") format("truetype");
  font-weight: normal;
}
a:hover {
  text-decoration: none;
}
.navbar-style img{
  max-height: 65px;
}
.bg-light {
  background-color: #fffff!important;
}
.container {
  max-width: 1430px;
}
.header-section .nav-link{
  font-family: Gothan;
  font-size: 18px;
  text-transform: uppercase;
}
.header-section .nav-link.active ,.footer-section .nav-link.active {
  color:#fbac18!important;
}
.footer-section{
  background: #7b7f88;
}
.footer-section .nav-link{
  font-family: 'Gotham-MediumPro';
  padding: 34px 0px 34px 0px;
  font-size: 17.55px;
  text-transform: uppercase;

}
.footer-section h4, .footer-section .nav-link{
  color:#fff!important;
}
.footer-section .list-inline {
  margin-bottom: 0px;
}
.footer-section h4 {
  font-family: 'Gotham BookPro';
  font-size: 18px;
}
.pb-55{
  padding-bottom: 55px;
}

.footer-section .nav-link.intern:before {
  content: "/";
  color: #ffff;
  margin-right: 16px;
}
.header-section .nav-link {
  font-size: 18px;
}
.navbar-light .navbar-brand , .navbar{
  padding: 0rem 1rem;
}
.header-section .navbar {
  padding: 17px 1rem 30px 1rem;
  background: #ffff;
}
.header-section .navbar-nav{
  margin-bottom: auto!important;
  margin-top: 40px;
}
.header-section .nav-link {
  display: block;
  padding: 0rem 1rem;
}
.container-section h2{
  font-family: "Gotham-BoldPro";
  font-size: 99.48px;
  color:#ffffff;
  word-break: break-word;
}
.container-section h4{
  font-family: "Gotham-MediumPro";
  font-size: 18px;
  color:#ffffff;
}
.container-section ul{
  columns: 2;
  -webkit-columns: 2;
  -moz-columns: 2;
  padding: 0px 17px;
  margin-bottom: 5px;
}
.container-section ul li{
  font-family: "Gotham BookPro";
  font-size:18px;
  color:#ffffff;

}
.container-section ul li:nth-child(1){
  list-style: none;
  margin-left: -17px
}

.container-section ul li:nth-child(1),.container-section ul li:nth-child(3) {
  margin-bottom: 30px;
}
.container-section ul li:nth-child(1),.container-section ul li:nth-child(2){
  margin-right: 28.5px;
}
.container-section ul li:nth-child(3),.container-section ul li:nth-child(4){
  margin-left: 28.5px;
}
.container-section .row{
  margin:0px
}
.button-info{
  font-family: "Gotham-MediumPro";
  font-size:18px;
  color:#ffffff;
  background: #fbac18;
  padding: 2px 25px;
  border-radius: 50px;
  margin-bottom:33px;
  margin-top: 14px;
}
.ml40{
  margin-left: 40px!important;
}
.container-section ul.no-columns {
  columns: 1;
  -webkit-columns: 1;
  -moz-columns: 1;
}
.container-section ul.no-columns li{
  margin-left: 0px;
  margin-right: 0px;
}
.container-section .no-list-style li{
  list-style: none!important;
}
.w51p{
  width: 51%!important;
}
.container-section ul.no-columns{
  padding:0px!important;
}
.btn-up {
  margin-top: -36px;
  margin-bottom: 60px;
}

.loading_panel {
  position: absolute;
  width: 100%;
  height: 0%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ffffff;
  animation-name: learn;
  animation-duration: 3s;
  opacity: 0;
  z-index: 20;
}

.sms-solutions{

}

.section-content-page ul.content-lists li.white::before{
  color: #ffffff!important;
}

@keyframes learn {
  from {opacity: 1;height: 100%;top:0%;bottom:100%}
  to {opacity: 0;height: 0%;top: 50%;bottom:0%}
}

.centered {
  position: fixed;
  top: 50%;
  left: 50%;
  margin-top: -100px;
  margin-left: -146px;
  z-index: inherit;
}
#menu {
  transition: height 1.5s ease;
}
@media only (min-width: 200px) and (max-width: 900px) {
  .container-section{
    width: auto!important;
    position: relative;
  }
  .centered {
    position: fixed;
    top: 8%;
    /* left: 50%; */
    margin-top: 27%;
    margin-left: -50%;
    width: 100%;
    padding: 20%;
  }
}



.breadcrumbs-list{
  margin:30px 0px 35px 0px;
}
.breadcrumbs-list li a{
  font-family: "Gotham-MediumPro";
  font-size: 18px;
  color:#000000;
}
.breadcrumbs-list li a{
  font-family: "Gotham-MediumPro";
  font-size: 18px;
  color:#000000;
}
.breadcrumbs-list li a.active{
  color:#fbac18;
}
.breadcrumbs-list li a.intern:before {
  content: "/";
  color: #fbac18;
  margin-right: 4px;
}
.breadcrumbs-list .list-inline-item:not(:last-child) {
  margin-right: 4px;
}
.section-content-page h4{
  font-family: 'Gotham-MediumPro';
  font-size: 18px;
  color:#000000;
}
.section-content-page ul li{
  font-family: 'Gotham BookPro';
  font-size: 18px;
  color:#000000;
}
.section-content-page ul.content-lists {list-style: none}
.section-content-page ul.content-lists li::before {
  content: "\2022";
  color: #fbac18;
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -25px;
}
.section-content-page .side-pages{
  margin-top:45px
}
.side-pages .image-containers{
  height:160px;
  width:100%;
  background-position: center;
  background-size: cover;
  border-top: 4px solid #fbac18;
}
.side-pages h4{
  padding: 22px 24px 27px 24px;
  margin-bottom: 0px;
  font-family: 'Gotham BookPro';
  font-size: 24px;
  color:#000000;
  width: 100%;
}
.side-pages p{
  padding: 0px 24px 37px 24px;
  margin-bottom: 0px;
  font-family: 'Gotham BookPro';
  font-size: 18px;
  width: 100%;
  color:#ffffff;
}
.side-pages .button-info{
  background: #ffffff;
  margin: 0px 24px 0px 33px;
}
.side-pages a.box{
  width: 100%;
  height: 290px;
  position: relative;
  display: inline-block;
  margin-bottom: 85px;
}
.side-pages a.box:hover {
  text-decoration: none;
  box-shadow: 0px 16px 27px #80808052;
  .image-containers{
    height:70px;
    transition: 0.9s;
  }
  p{
    color:#000000;
  }
  .button-info{
    background: #fbac18;
    transition: 3.0s;
  }
  transition: 0.9s;
  transition-timing-function: ease-out;
  transition-timing-function: cubic-bezier(0, 0, 0.58, 1);
}

.content-page p{
  font-family: 'Gotham BookPro';
  font-size: 18px;
  color:#000000;
}

.content-page p.info-warn{
  font-family: 'Gotham BookPro';
  font-size: 18px;
  font-style: italic;
  color:#fbac18;
  //margin-left: 29px;
  margin-left: 0px;
}
.green-numbers{
  margin-bottom: 60px;
}
.payments{
  margin-bottom: 60px;
}
.section-image{
  background-size: cover;
  background-position: center;
}
.section-content-page h5{
  font-family: 'Gotham BookPro';
  font-size: 24px;
  color:#000000;
  margin-bottom: 39px;
}
.content-lists.next.mt-40 {
  margin-top: 40px;
}
.txt-dec {
  text-decoration: underline;
}
.content-lists.next li {
  //font-family: "Gotham-MediumPro";
}
.ml-0{
  margin-left: 0px;
}
.section-solutions{
  margin-top: 100px;
}

.bullet-line{
  padding: 0px!important;
}
.bullet-line li{
  margin: 0px auto;
  display: inline-grid;
  width: 181px;
  background: #929292;
  border-radius: 93px;
  height: 181px;
  margin-right: 19px;
}
.bullet-line li img{
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
  margin-top: 25px;
  margin-bottom: 14px;
}
.bullet-line li h5{
  font-family: Gotham-MediumPro;
  font-size: 12px;
  text-align: center;
  color:#ffffff;
}

.bullet-line li.direction:before {
  content: '\25BA';
  color: #929292;
  float: right;
  //width: 100%;
  top: 29%;
  position: absolute;
  margin-left: 152px;
  font-size: 51px;
}
.section-sms-solutions .content-page{
  position: relative;
  height: 100%;
}
.section-sms-solutions h3{
  position: absolute;
  bottom: 0;
  left: 15px;
  color:#ffffff;
  font-size: 99px;
  font-family: Gotham-BoldPro;
}

.bullet-line-partners{
  padding: 0px!important;
  //max-width: 760px;
}
.bullet-line-partners li{
  background-image: url("../assets/img/bullet-partners.jpg");
  margin: 0px auto;
  display: inline-grid;
  width: 190px;
  border-radius: 59px 0px 77px 0px;
  height: 170px;
}
.bullet-line-partners li h6 {
  font-family: Gotham-BoldPro;
  font-size:11px;
  text-transform: uppercase;
  margin-top: 32px;
  margin-right: 35%;
  text-align: center;
}
.bullet-line-partners li h5 {
  font-family: Gotham-BoldPro;
  font-size: 11px;
  margin-top: 6px;
  text-align: center;
  margin-left: 20px;
  line-height: 13px;
}

.tooltip {
  position: relative;
  display: inline-block;
  opacity: 1;
  margin-bottom: 75px;
  z-index: auto;
}
.tooltip img{
  width: 100%;
  padding-bottom: 10px;

}
/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  width: 0px;
  height: 225px;
  background-color: #ffffff;
  color: #fff;
  text-align: left;
  box-shadow: 7px 12px 22px 0.2px #7b7b7b;;
  padding: 25px;

  /* Position the tooltip */
  position: absolute;
  z-index: 5;
  top: 100%;
  left: 50%;
  margin-left: -60px;
}
.tooltiptext h4 {
  font-size: 20px;
  font-family: Gotham-MediumPro;
  margin-bottom: 15px;
}
.tooltiptext p {
  height: 200px;
  overflow-y: auto;
  font-size: 14px;
  font-family: "Gotham Light";
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
  width: 450px;
}

.mb65{
  margin-bottom: 65px;
}
.mb35{
  margin-bottom: 35px;
}
.mb0{
  margin-bottom: 0px;
}
.contact .section-content-page h5{
   font-family: Gotham-BoldPro;
  font-size: 18px;
 }
.contact .section-content-page p{
  font-family: "Gotham Light";
  font-size: 18px;
}

#gmap_canvas{
  width: 100%;
  height: 373px;
  border-top: 5px #fbac18 solid;
}
.colorWhite{
  color:#ffffff!important;
}
.colorOrange{
  color:#FEAD03!important;
}
.bullet-line-about li{
  background-image: url("../assets/img/bullet-about.jpg") !important;
}
.bullet-line-about li h5{
  font-family: Gotham-BoldPro;
  font-size: 18px;
  margin-top: -6px;
  text-align: center;
  margin-left: 20px;
  line-height: 18px;
}
.fontFGMPB{
  font-family: "Gotham-BoldPro";
  font-weight: 600;
}
.moveLeft{
  left: -150%!important;
  margin-left: 0px!important;
}
.careers .side-pages a.box h5{
  padding-top: 100px;
  padding-left: 31px;
  font-family: 'Gotham-BoldPro';
  color: #ffffff;
  text-shadow:
          -1px -1px 0 #000,
          1px -1px 0 #000,
          -1px 1px 0 #000,
          1px 1px 0 #000;
}
.careers .side-pages a.box h4{
  display: none;
}
.careers .side-pages a.box:hover , .careers .side-pages h5:hover {
  text-decoration: none;
  box-shadow: 0px 16px 27px #80808052;
  .image-containers{
    //height:100%;
    transition: 0.9s;
  }
  h4{
    display: block;
    transition: 3.0s;
  }
  h5{
    display: none;
    transition: 3.0s;
  }
  .button-info{
    background: #fbac18;
    transition: 3.0s;
  }
  transition: 0.9s;
  transition-timing-function: ease-out;
  transition-timing-function: cubic-bezier(0, 0, 0.58, 1);
}

.mb0{
  margin-bottom: 0px!important;
}


.cookie-overlay {
  position: fixed;
  bottom: 1rem;
  left: 1rem;
  background: #fff;
  z-index: 2051;
  line-height: 22px;
  font-size: 14px;
  border-radius: 6px;
  max-width: 500px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  font-family: "Gotham Light";
}
.mr{
  margin-right: 20px!important;
}
.accept-cookies{
  background-color: #fbac18!important;
  border-color: #fbac18!important;
  font-family: "Gotham Light";
}
.footer-section ul.indicators{
  margin-left: 0px;
  padding-left: 0px;
  list-style-type: none;
}
.footer-section ul.indicators li{
  display: inline-block;
  color:#ffffff;
  vertical-align:top;
}
.ml10 {
  margin-left: 10px;
}