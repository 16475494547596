@import './scss/style.scss';
.section-solutions{
  background-image: url("./assets/img/solutions-background.jpg");
}
.section-sms-solutions{
  background-image: url("./assets/img/solutions-background.jpg");
  height: 566px;
}
.section-software-development{
  background-image: url("./assets/img/dev-software.jpg");
}
.section-green-numbers{
  background-image: url("./assets/img/green-numbers.jpg");
}
.section-benefis{
  background-image: url("./assets/img/benefis.jpg");
}
.section-mobile-apps{
  background-image: url("./assets/img/mobile-apps.jpg");
}
.section-payment{
  background-image: url("./assets/img/payment.jpg");
}
.section-telecomunications{
  background-image: url("./assets/img/telecomunications.jpg");
}
.section-homepage{
  position:relative;
  width:100%;
  float:left;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  -moz-box-shadow: inset 0 -10px 10px -10px #000000;
  -webkit-box-shadow: inset 0 -10px 10px -10px #000000;
  box-shadow: inset 0 -370px 383px -81px #000000;
}
.fixed-bg {
  background-attachment: fixed!important;
  background-repeat: no-repeat!important;
  background-size: cover!important;
  background-position: center center!important;
  height: 80%!important;
  width: 100%!important;
  display: table!important;
}
.fixed-bg-page {
  background-attachment: fixed!important;
  background-repeat: no-repeat!important;
  background-size: cover!important;
  background-position: center -210px!important;

  width: 100%!important;
  display: table!important;
}
.buttomContent{
  position: relative;
  height:963px;
}
.container-section {
  position: absolute;
  //width: 100%;
  width: 100%;
  bottom: 0px;
}
.pull-right {
  float: right;
}
@media only screen and (max-width: 715px) {

  .bullet-line li.direction:before {
    content: '';
    color: #ffffff;
    float: right;
    width: 0%;
    top: 0%;
    position: relative;
    margin-left: 0px;
    font-size: 0px;
  }
  .bullet-line {
    padding: 0px !important;
    text-align: center;
  }
  .bullet-line li{
    margin-bottom: 5px;
  }
  .side-pages a.box {
    text-decoration: none;
    box-shadow: 0px 16px 27px #80808052;
    .image-containers{
      height:70px;
      transition: 0.9s;
    }
    p{
      color:#000000;
    }
    .button-info{
      background: #fbac18;
      transition: 3.0s;
    }
    transition: 0.9s;
    transition-timing-function: ease-out;
    transition-timing-function: cubic-bezier(0, 0, 0.58, 1);
  }
  .careers .side-pages h5 {
    display:none
  }
  .careers .side-pages a.box h4{
    display:block
  }
}
.footer-section h4{
  font-size: 15px;
}
.footer-section a{color:#ffffff}
.footer-section a:hover{color:#ffffff;text-decoration: none!important;}


@media only screen and (max-width: 1000px) {

  .container{
    width: 100%;
    max-width: 100%;
  }
  .container-section ul{
    columns: 1;
    -webkit-columns: 1;
  }
  .pull-right {
    float: left;
  }
  .loading_panel{
    position: relative;
    width: 0%;
    height: 0%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ffffff;
    animation-name: learn;
    animation-duration: 3s;
    opacity: 0;
    z-index: 20;
    display: none;
  }
  .loading_panel .centered {
    position: relative;
    top: 0%;
    left: 0%;
    margin-top: -100px;
    margin-left: -146px;
    z-index: inherit;
    display: none;
  }
  .container-section ul li {
    word-break: break-word;
  }
  .container-section ul li:nth-child(1), .container-section ul li:nth-child(2) {
     margin-right: 0px;
  }
  .container-section ul li:nth-child(1), .container-section ul li:nth-child(3) {
    margin-bottom: 0px;
  }
  .container-section ul li:nth-child(3), .container-section ul li:nth-child(4) {
    margin-left: 0px;
  }
  .container-section h2 {
    font-size: 50px;
  }
  .container-section ul li {
    font-size: 18px;
  }
  .container-section {
    position: absolute;
    width: 96%;
    bottom: 0px;
  }
  .section-solutions {
    margin-top: 0px;
  }
  .fixed-top {
     position: relative;

  }
}
@media only screen and (max-width: 1500px) {
  .container-section h2 {
    font-size: 30px;
  }
  .container-section ul li {
    font-size: 14px;
  }
  .container-section {
    position: absolute;
    width: 98%;
    bottom: 0px;
  }
}

@media only screen and (max-width: 950px) {
  .section-sms-solutions{
    margin-top: 0px!important;
  }
  .contact{
    margin-bottom:60px;
  }
  .section-sms-solutions h3 {
    left: 0;
    font-size: 48px;
  }
  .section-content-page .mt20_responsive{
    margin-top: 20px!important;
  }
  .mb10_responsive{
    margin-bottom: 0px;
  }
  .tooltip {
    margin-bottom: 5px;
  }
  .container-section h2 {
    font-size: 30px;
  }
  .container-section ul li {
    font-size: 14px;
  }
  .buttomContent img.img-responsive{
    width: 100%;
  }
  .w51p {
    width: 100% !important;
  }
  .w50p-responsive{
    width: 50%!important;
  }
  .ml40{
    margin-left: 10px!important;
  }
  .btn-up {
    margin-top: 20px;
    margin-bottom: 60px;
  }
  .footer-section .nav-link {
    padding: 0px;
    margin-bottom: 5px;
    margin-top: 5px;
  }
  .footer-section .list-inline {
    margin-bottom: 10px;
  }
}
@media only screen and (max-width: 1100px) {
  .pull-right {
    float: right;
    margin-right: 5px;
  }
  .container-section {
    width: 96% !important;
  }
}
@media only screen and (max-width: 400px) {
  .container-section h2 {
    font-size: 24px;
  }
  .container-section ul li {
    font-size: 12px;
  }
}